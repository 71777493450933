<template>

    <div v-if="loaded">
        <menu-vue :user-info="userInfo"></menu-vue>
        <router-view :user-info="userInfo" ></router-view>
    </div>
    <div v-else>
        <div class="container-fluid" style="height: 100vh;">
            <div class="row align-items-center justify-content-center h-100">
                <div class="col-sm col-lg-4 md-auto">
                    <div class="text-center">
                        <div class="spinner-border text-white md-auto" role="status" style="height: 5em; width: 5em;">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <br>
                    <p class="text-center text-white lead">Chargement en cours...</p>
                </div>
                <br>
                <pre v-if="error">Something wrong => {{error}}</pre>
            </div>
        </div>
    </div>

</template>

<script setup>

    import { onMounted, ref } from 'vue';
    import axios from 'axios';
    import MenuVue from './components/Menu.vue';
    import { useRouter } from 'vue-router';

    let userInfo = ref(null);
    let loaded = ref(false);
    let error = ref(null);
    
    const router = useRouter();

    onMounted( () => {
        load();
    })

    function load(){
        checkMe();
    }
    
    function checkMe(){
        axios.get("/api/users/me")
            .then( (answer) => {
                userInfo.value = answer.data.meResponse;
                loaded.value = true;
                setTimeout( () => checkMe() , 10000 );
            })
            .catch( (e) => {
                error.value = (e.response) ? e.response.data.message : e; 
                router.go(0);
            });
    }


</script>
