<template>
    <header id="menuApp" class="navbar navbar-expand navbar-dark bg-dark flex-column flex-md-row px-3">
        
        <router-link :to="{name: 'Dashboard.datacenters'}" class="navbar-brand mr-0 mr-md-2">
            <i class="bi bi-cloud m-1"></i> FormaCloud
        </router-link>

        <button class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbar" >
            <span class="navbar-toggler-icon"></span>
        </button>

        <div id="navbar" class="collapse navbar-collapse">
            
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                    <router-link :to="{name: 'Dashboard.datacenters'}" class="nav-link"  exact-active-class="active">Datacenters</router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="{name: 'Dashboard.machines'}" class="nav-link"  exact-active-class="active">Machines</router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="{name: 'Dashboard.shop'}" class="nav-link"  exact-active-class="active">MarketPlace</router-link>
                </li>

            </ul>

            <form action="" class="d-flex">

                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    
                    <li class="nav-item my-auto">
                        <a href="#" class="nav-link">
                            <i class="bi bi-alarm me-2"></i> {{ userInfo.runningHours.toFixed(3) }}h / {{ (userInfo.quota_id.maxHours*userInfo.quota_id.maxInstances) }}h
                            <div class="progress" style="height: 3px;">
                                <div class="progress-bar progress-bar-striped progress-bar-animated" :style="'width: '+ ( userInfo.runningHours / (userInfo.quota_id.maxHours*userInfo.quota_id.maxInstances) )*100+'%;'" role="progressbar" :aria-valuenow="( userInfo.runningHours / (userInfo.quota_id.maxHours*userInfo.quota_id.maxInstances) )*100" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a href="#" class="position-relative nav-link" @click="showEventsBox = true">
                            <i class="bi bi-bell-fill me-2" data-bs-toggle="modal" data-bs-target="#alertModal"></i>
                            <span class="badge bg-danger" v-if="eventsUnread > 0">{{eventsUnread}}</span>
                        </a>
                    </li>

                    <li class="nav-item dropdown">
                        <a href="#" class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <img :src="gravatar" alt="Avatar" class="me-1" style="border-radius: 50%;width:24px;">
                            {{ userInfo.firstName }} {{ userInfo.lastName }}
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li>
                                <a href="#" class="dropdown-item" @click="showProfilView = true">
                                    <i class="bi bi-card-checklist me-2"></i>Mon Compte
                                </a>
                            </li>
                            <!-- <li>
                                <router-link :to="{name: 'Admin.groups'}" class="dropdown-item" v-if="userInfo.authLevel <= 1">
                                    <i class="bi bi-hash me-2"></i>Administration
                                </router-link>
                            </li> -->
                            <li>
                                <router-link :to="{name: 'Admin2.dashboard'}" class="dropdown-item" v-if="userInfo.authLevel <= 1">
                                    <i class="bi bi-hash me-2"></i>Administration
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <hr class="dropdown-divider">
                            </li>
                            <li>
                                <a href="#" class="dropdown-item" @click.prevent="handleLogout">
                                    <i class="bi bi-box-arrow-in-right me-2"></i>Se déconnecter
                                </a>
                            </li>
                        </ul>

                    </li>

                </ul>
            </form>

        </div>

        <EventsBox modal-id="EventsBox"
            :show="showEventsBox"
            :events="events"
            @reload="checkEvents(false)"
            @close="showEventsBox = false" />

        <ProfilView modal-id="ProfilView"
            :show="showProfilView"
            :user-info="userInfo"
            @close="showProfilView = false" />

        <ErrorBox modal-id="MenuErrorBox"
            :show="error"
            :message="error" 
            @close="error=false" />

    </header>
</template>

<script setup>

    import {defineProps, onMounted, ref, computed} from "vue";
    import { useRouter } from "vue-router";
    import crypto from "crypto";
    import EventsBox from "./EventsBox.vue";
    import axios from "axios";
    import ProfilView from "./ProfilView.vue";
    import ErrorBox from "../../admin/components/generics/ErrorBox.vue";

    const props = defineProps([
        "userInfo"
    ])

    const router = useRouter();

    let showEventsBox = ref(false);
    let showProfilView = ref(false);
    let events = ref([]);
    let error = ref(false);

    let eventsUnread = computed( () => events.value.filter( e => !e.readed ).length )

    // Compute avatar user
    let gravatar = computed( function() {
        if(props.userInfo.email){
            return `https://www.gravatar.com/avatar/${crypto.createHash('md5').update(props.userInfo.email).digest("hex")}`;
        }
        else{
            return `https://www.gravatar.com/avatar/${crypto.createHash('md5').update("gravatar@formacloud.ltd").digest("hex")}`;
        }
    })

    onMounted( () => {
        load();
    })
    
    async function handleLogout(){
        document.cookie = "ci2n_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT;";
        document.location = "/";
        // router.push("/login");
    }

    function load(){
        checkEvents(true);
    }

    function checkEvents(withTimeout){
        axios.get("/api/users/me/events")
            .then( answer => {
                events.value = answer.data.eventsResponse;
                if(withTimeout) setTimeout( () => checkEvents(true), 10000 );
            })
            .catch( answer => {
                error.value = (answer.response) ? (answer.response.data.message) ? answer.response.data.message : answer : answer;
            });
    }

</script>

<style>

</style>