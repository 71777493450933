<template>
    <div>
        <div class="modal fade" tabindex="-1" :id="modalId" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" v-if="visible">

                    <div class="modal-body">
                        <p class="text-center">
                            <img :src="gravatar" alt="Avatar" class="img-thumbnail" style="border-radius: 50%;">
                            <br>
                            <span class="lead">
                                {{(localUser.firstName) ? `${localUser.firstName} ${localUser.lastName}` : "Ne s'est pas encore connecté"}}
                            </span>
                            <br>
                            <small><em>{{localUser.email}}</em></small>
                            <br>
                            <small><em>{{ (group) ? group : "-"}} / {{ (userLimits) ? userLimits.profilName : "-"}}</em></small>

                        </p>                
                        
                        <label for="" class="form-label"><h6>Mettre à jour votre mot de passe local</h6></label>
                        <div class="card bg-light mb-3">
                            <div class="card-body">
                                <div class="mb-3">
                                    <label for="" class="form-label">Ancien</label>
                                    <input type="password" name="" id="" class="form-control form-control-sm" v-model="current" :disabled="disabled">
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <label for="" class="form-label">Nouveau</label>
                                        <input type="password" name="" id="" class="form-control form-control-sm" v-model="password" :disabled="disabled">
                                    </div>
                                    <div class="col">
                                        <label for="" class="form-label">Confirmation</label>
                                        <input type="password" name="" id="" class="form-control form-control-sm" v-model="confirmation" :disabled="disabled">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <label for="" class="form-label"><h6>Stats</h6></label>
                        <div class="card bg-light">
                            <div class="card-body text-center" v-if="!userLimits">
                                <span class="spinner spinner-border"></span>
                            </div>
                            <div class="card-body" v-else>
                                <div class="mb-3">
                                    <label for="" class="form-label">Infra(s) <small class="text-muted"><em>{{localUser.maxInfras-userLimits.freeInfras}}/{{localUser.maxInfras}}</em></small></label>
                                    <div class="progress" style="height:5px;">
                                        <div class="progress-bar" :class="{'bg-warning': ( (( (localUser.maxInfras-userLimits.freeInfras) /localUser.maxInfras)*100 ) >= 100) }"  role="progressbar" :style="'width: '+ (( (localUser.maxInfras-userLimits.freeInfras) / localUser.maxInfras)*100 ) +'%'"></div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="" class="form-label">Instance(s) <small class="text-muted"><em>{{userLimits.totalInstances - userLimits.freeInstances}}/{{userLimits.totalInstances}}</em></small></label>
                                    <div class="progress" style="height:5px;">
                                        <div class="progress-bar" :class="{'bg-warning': ( ((userLimits.totalInstances - userLimits.freeInstances) / userLimits.totalInstances)*100 ) >= 100}"  role="progressbar" :style="'width: '+( ((userLimits.totalInstances - userLimits.freeInstances) / userLimits.totalInstances)*100 )+'%'"></div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="" class="form-label">Stockage <small class="text-muted"><em>{{userLimits.totalStorage - userLimits.freeStorage}}/{{userLimits.totalStorage}}</em></small></label>
                                    <div class="progress" style="height:5px;">
                                        <div class="progress-bar" :class="{'bg-warning': ( ((userLimits.totalStorage - userLimits.freeStorage) / userLimits.totalStorage)*100 ) >= 100}"  role="progressbar" :style="'width: '+( ((userLimits.totalStorage - userLimits.freeStorage) / userLimits.totalStorage)*100 )+'%'"></div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="" class="form-label">Heure(s) <small class="text-muted"><em>{{ ( (userLimits.totalHours*userLimits.totalInstances) - userLimits.freeHours ).toFixed(2) }}/{{userLimits.totalHours*userLimits.totalInstances}}</em></small></label>
                                    <div class="progress" style="height:5px;">
                                        <div class="progress-bar" :class="{'bg-warning': ( ((userLimits.totalHours*userLimits.totalInstances) - userLimits.freeHours) / (userLimits.totalHours*userLimits.totalInstances) )*100 >= 100}"  role="progressbar" :style="'width: '+(((userLimits.totalHours*userLimits.totalInstances) - userLimits.freeHours) / (userLimits.totalHours*userLimits.totalInstances))*100+'%'"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm" @click.prevent="close()" :disabled="disabled">
                            <i class="bi bi-x me-2"></i>Fermer
                        </button>
                        <button class="btn btn-sm" :disabled="disabled" @click="saveModifications()">
                            <i class="bi bi-save me-2"></i>Enregistrer les modifications
                        </button>

                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

    import { defineProps, defineEmits, onMounted, computed, ref, toRef } from 'vue';
    import Modal from "bootstrap/js/dist/modal";
    import axios from "axios";
    import crypto from "crypto";

    let props = defineProps(["modalId","show","userInfo"]);
    let emits = defineEmits(["close","saved"]);

    let visible = computed( () => {
        if(props.show){
            let myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
            load();
            myModal.show();
        }
        else{
            try{
                let myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
                myModal.hide();
            }
            catch(e){
                return props.show;
            }
        }
        return props.show;
    });

    // Local vars
    let disabled = ref(false);
    let localUser = toRef(props, "userInfo");
    let userLimits = ref(null);
    let group = ref(null);
    let current = ref("");
    let password = ref("");
    let confirmation = ref("");

    // Compute avatar user
    let gravatar = computed( function() {
        if(props.userInfo.email){
            return `https://www.gravatar.com/avatar/${crypto.createHash('md5').update(props.userInfo.email).digest("hex")}`;
        }
        else{
            return `https://www.gravatar.com/avatar/${crypto.createHash('md5').update("gravatar@formacloud.ltd").digest("hex")}`;
        }
    })

    onMounted( () => {
        
    })

    function load(){
        axios.get("/api/users/me/limits")
            .then( answer => {
                userLimits.value = answer.data;
                return axios.get("/api/users/me/group");
            })
            .then( answer => {
                group.value = answer.data.groupResponse.name;
            })
            .catch( answer => {

            })
    }

    function close(){
        emits("close");
    }

    function saveModifications(){

        if(current.value === "" && password.value === "" && confirmation.value === ""){
            disabled.value = false;
            close();
        }
        else{
            disabled.value = true;
            axios.put(`/api/users/me/password`, {
                current: current.value,
                password: password.value,
                confirmation: confirmation.value
            })
            .then( answer => {
                disabled.value = false;
                close();
            })
            .catch( answer => {
                disabled.value = false;
                alert( (answer.response.data.message) ? answer.response.data.message : answer );
            })
        }

    }

</script>