<template>
    <div>
        <div class="modal fade" tabindex="-1" :id="modalId" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                <div class="modal-content" v-if="visible">

                    <div class="modal-header">
                        <h6 class="modal-title">Evènements ({{events.length}})</h6>
                        <button type="button" class="btn-close" @click.prevent="close()" aria-label="Close"></button>
                    </div>

                    <div class="modal-body">
                        
                        <div class="list-group list-group-action" v-if="events.length > 0">
                            <li class="list-group-item list-group-item-action ps-2" v-for="event in events" :key="event._id" role="button" @click="readEvent(event)">
                                <table class="table table-sm table-borderless mb-0">
                                    <tr>
                                        <td>
                                            <i class="bi me-2" :class="{'bi-check-square-fill text-success': event.level === 1, 'bi-info-square-fill text-primary': event.level === 0, 'bi-exclamation-square-fill text-warning': event.level === 2, 'bi-x-square-fill text-danger': event.level === 3}"></i>
                                        </td>
                                        <td class="w-100 fw-bold">
                                            Le {{ (new Date(event.date)).toLocaleDateString() }} à {{ (new Date(event.date)).toLocaleTimeString() }}
                                            <i class="bi bi-envelope-fill text-warning float-end mb-0 p-0" v-if="!event.readed"></i>
                                            <i class="bi bi-envelope-open float-end mb-0 p-0" v-else></i>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>
                                            <p class="text-muted fw-lighter mb-0" style="text-align: justify;">{{event.message}}</p>
                                        </td>
                                    </tr>
                                </table>
                            </li>
                        </div>
                        <div v-else>
                            <div class="alert alert-light mb-0">
                                <i class="bi bi-info-circle-fill me-2"></i>Pas de messages
                            </div>
                        </div>

                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn" @click.prevent="close()" :disabled="disabled">
                            <i class="bi bi-x me-2"></i>Fermer
                        </button>
                        <button type="button" class="btn" :disabled="disabled" @click="flush()">
                            <i class="bi bi-envelope-dash text-danger me-2"></i>Tout supprimer
                        </button>
                        <button type="button" class="btn" :disabled="disabled" @click="readAll()">
                            <i class="bi bi-envelope-open me-2"></i>Tout lire
                        </button>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

<script setup>

    import { defineProps, defineEmits, onMounted, computed, ref, toRefs } from 'vue';
    import Modal from "bootstrap/js/dist/modal";
    import axios from "axios";

    let props = defineProps(["modalId","show","userInfo","events"]);
    let emits = defineEmits(["close","reload"]);
    let disabled = ref(false);

    let visible = computed( () => {
        if(props.show){
            let myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
            myModal.show();
        }
        else{
            try{
                let myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
                myModal.hide();
            }
            catch(e){
                return props.show;
            }
        }
        return props.show;
    });

    onMounted( () => {

    })

    function readAll(){
        axios.post(`/api/users/me/events/read-all`)
            .then( answer => {
                emits("reload");
            })  
            .catch( answer => {
                alert(answer);
            })  
    }

    function readEvent(event){
        axios.post(`/api/users/me/events/${event._id}/read`)
            .then( answer => {
                emits("reload");
            })  
            .catch( answer => {
                alert(answer);
            })  
    }

    function flush(){
        axios.delete(`/api/users/me/events/flush`)
            .then( answer => {
                emits("reload");
            })  
            .catch( answer => {
                alert(answer);
            })  
    }

    function close(){
        emits("close");
    }

</script>